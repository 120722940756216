import React, { useState, useEffect } from "react"
import { functions, database } from "../../../../firebase"
import {
  FaInstagram,
  FaCheck,
  FaExclamationCircle,
  FaWhatsapp,
} from "react-icons/fa"
import { updateAccounts } from "../../../../redux/account/account.actions"
import {
  selectAccounts,
  selectAccountId,
} from "../../../../redux/account/account.selectors"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"
import "../../../App.css"
import ReelerButton from "../../../commons/ReelerButton"
import moment from "moment"
import SpinnerComponent from "../../../commons/SpinnerComponent"
import { Form } from "react-bootstrap"

const scope = ["business_management", "whatsapp_business_messaging"]

// https://developers.facebook.com/docs/whatsapp/embedded-signup/embed-the-flow#step-6--optional---legacy-version-support

const WhatsappIntegration = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const account = useSelector(selectAccounts)
  const accountId = useSelector(selectAccountId)

  const [error, setError] = useState(null)

  const fbLogin = () => {
    setLoading(true)
    setError(null)
    // https://developers.facebook.com/docs/facebook-login/web
    window.FB.login(
      function (response) {
        if (response.status === "connected") {
          if (response.authResponse && response.authResponse.grantedScopes) {
            handleFBLoginResponse(response)
          } else {
            setError(
              "Reeler needs more permissions to function properly. Make sure you have sufficient permissions to connect Reeler to your WhatsApp Business account and grant Reeler access to all asked permissions."
            )
            setLoading(false)
          }
        } else {
          setError("You are not logged in to Facebook.")
          // The person is not logged into your webpage or we are unable to tell.
          setLoading(false)
        }
      },
      {
        config_id: process.env.REACT_APP_FACEBOOK_APP_WHATS_APP_CONFIG_ID,
        scope: scope,
        return_scopes: true,
        response_type: "code",
        override_default_response_type: true,
        extras: {
          feature: "whatsapp_embedded_signup",
          // Display original flow to user
          sessionInfoVersion: 3,
        },
      }
    )
  }

  const getSharedWABAID = async (inputToken, accessToken) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://graph.facebook.com/v20.0/debug_token?input_token=${inputToken}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
    const { data } = await axios.request(config)

    console.log("Response shared WABA ID: ", data)
    // Get latest shared WABA ID
    if (!data.error) {
      if (data.data?.granular_scopes?.length > 0) {
        const wabaId = data.data.granular_scopes[0]?.target_ids[0]
        return wabaId
      } else {
        console.log("Error: No shared WABA ID")
        return null
      }
    } else {
      console.log("Error", data.error)
      return null
    }
  }
  const getPhoneNumber = async (wabaId, accessToken) => {
    const { data } = await axios.get(
      `https://graph.facebook.com/v20.0/${wabaId}/phone_numbers?access_token=${accessToken}`
    )
    console.log("Response phone numbers: ", data)
    // Get latest shared WABA ID
    if (!data.error) {
      if (data?.data?.length > 0) {
        return data.data[0]
      } else {
        return null
      }
    } else {
      console.log("Error", data.error)
      return null
    }
  }
  const deregisterPhoneNumber = async (phoneNumberId, accessToken) => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `https://graph.facebook.com/v20.0/${phoneNumberId}/deregister`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }

    const { data } = await axios.request(config)
    console.log("Response register phone number: ", data)
    if (data.success) {
      return data.success
    } else {
      return false
    }
  }
  const registerPhoneNumber = async (phoneNumberId, pin, accessToken) => {
    let body = JSON.stringify({
      messaging_product: "whatsapp",
      pin: pin,
      data_localization_region: "DE",
    })

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `https://graph.facebook.com/v20.0/${phoneNumberId}/register`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: body,
    }

    const { data } = await axios.request(config)
    console.log("Response register phone number: ", data)
    if (data.success) {
      return data.success
    } else {
      return false
    }
  }
  const twoStepVerification = async (phoneNumberId, pin, accessToken) => {
    let body = JSON.stringify({
      pin: pin,
    })

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `https://graph.facebook.com/v20.0/${phoneNumberId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: body,
    }
    const { data } = await axios.request(config)
    console.log("Response two-step verification: ", data)

    if (data.success) {
      return data.success
    } else {
      return false
    }
  }

  const getSystemUserAccessToken = async code => {
    var getSystemUserAccessToken = functions.httpsCallable(
      "getsystemuseraccesstoken"
    )

    const { data } = await getSystemUserAccessToken({
      code: code,
      accountId: account.id,
    })
    return data
  }

  // const sessionInfoListener = async event => {
  //   if (event.origin == null) {
  //     return
  //   }

  //   // Make sure the data is coming from facebook.com
  //   if (!event.origin.endsWith("facebook.com")) {
  //     return
  //   }

  //   try {
  //     const data = JSON.parse(event.data)
  //     if (data.type === "WA_EMBEDDED_SIGNUP") {
  //       // if user finishes the Embedded Signup flow
  //       if (data.event === "FINISH") {
  //         const { phone_number_id, waba_id } = data.data
  //         console.log(
  //           "Phone number ID ",
  //           phone_number_id,
  //           " WhatsApp business account ID ",
  //           waba_id
  //         )

  //         try {
  //           await database.accounts.doc(accountId).update({
  //             "integrations.whatsapp.phone_number_id": phone_number_id,
  //             "integrations.whatsapp.waba_id": waba_id,
  //           })

  //           // Update redux
  //           dispatch(
  //             updateAccounts({
  //               integrations: {
  //                 ...account.integrations,
  //                 whatsapp: {
  //                   ...account.integrations.whatsapp,
  //                   phone_number_id: phone_number_id,
  //                   waba_id: waba_id,
  //                 },
  //               },
  //             })
  //           )
  //         } catch (err) {
  //           console.log(err)
  //           setError(
  //             "Reeler could not get the WhatsApp business account id. Please contact Reeler support. "
  //           )
  //           setLoading(false)
  //         }
  //       }
  //       // if user reports an error during the Embedded Signup flow
  //       else if (data.event === "ERROR") {
  //         const { error_message } = data.data
  //         console.error("error ", error_message)
  //       }
  //       // if user cancels the Embedded Signup flow
  //       else {
  //         const { current_step } = data.data
  //         console.warn("Cancel at ", current_step)
  //       }
  //     }
  //   } catch {
  //     // Don’t parse info that’s not a JSON
  //     console.log("Non JSON Response", event.data)
  //   }
  // }

  // useEffect(() => {
  //   window.addEventListener("message", sessionInfoListener)

  //   return () => {
  //     window.removeEventListener("message", sessionInfoListener)
  //   }
  // }, [])

  const handleFBLoginResponse = async response => {
    const authResponse = response.authResponse
    console.log("FB response", response)

    try {
      const code = authResponse.code

      if (!code) {
        setError(
          "Reeler could not get the code. Please contact Reeler support. "
        )
        setLoading(false)
        return
      }

      const accessToken = await getSystemUserAccessToken(code)

      const wabaId = await getSharedWABAID(accessToken, accessToken)

      if (!wabaId) {
        setError(
          "Reeler could not get the WhatsApp business account id. Please contact Reeler support. "
        )
        setLoading(false)
        return
      }
      console.log("WABAID:", wabaId)
      const phoneNumber = await getPhoneNumber(wabaId, accessToken)

      if (!phoneNumber) {
        setError(
          "Reeler could not get the phone number. Please contact Reeler support. "
        )
        setLoading(false)
        return
      }

      // Check if phone number is already registered
      if (
        phoneNumber.id === account?.integrations?.whatsapp?.phone_number?.id
      ) {
        const deregistered = await deregisterPhoneNumber(
          account.integrations.whatsapp.phone_number.id,
          accessToken
        )

        console.log("Deregistered: ", deregistered)

        if (!deregistered) {
          setError(
            "Reeler could not deregister the phone number. Please contact Reeler support. "
          )
          setLoading(false)
          return
        }
      }

      let pin
      if (account?.integrations?.whatsapp?.pin) {
        pin = account.integrations.whatsapp.pin
      } else {
        //pin = "123456"
        pin = Math.floor(100000 + Math.random() * 900000)
      }

      // Register phone number
      const registered = await registerPhoneNumber(
        phoneNumber.id,
        pin,
        accessToken
      )

      if (!registered) {
        setError(
          "Reeler could not register the phone number. Please contact Reeler support. "
        )
        setLoading(false)
        return
      }

      // Two-step verification
      const verified = await twoStepVerification(
        phoneNumber.id,
        pin,
        accessToken
      )

      if (!verified) {
        setError(
          "Reeler could not verify the phone number. Please contact Reeler support. "
        )
        setLoading(false)
        return
      }

      let data = {
        phone_number: phoneNumber,
        waba_id: wabaId,
        system_user_access_token: accessToken,
        pin,
      }

      await database.accounts.doc(accountId).update({
        "integrations.whatsapp": data,
      })

      // Update redux
      dispatch(
        updateAccounts({
          integrations: {
            ...account.integrations,
            whatsapp: data,
          },
        })
      )

      setLoading(false)
    } catch (err) {
      console.log(err)
      setError(
        "Reeler could not get the system user access token. Please contact Reeler support. "
      )
      setLoading(false)
    }
  }

  return account ? (
    <div
      className="ml-3 p-3"
      style={{
        border: "1px solid lightgrey",
        borderRadius: "5px",
        width: "300px",
      }}
    >
      <h5>
        <FaWhatsapp className="icon-color mr-3" />
        WhatsApp
      </h5>
      <p>
        Connect your WhatsApp Business account to Reeler to enable collection of
        content through WhatsApp messaging.
      </p>
      {error ? (
        <div className="d-flex flex-row align-items-center mb-3">
          <FaExclamationCircle
            style={{ color: "var(--reeler-danger)" }}
            className="mr-2"
          />
          <span>{error}</span>
        </div>
      ) : null}

      {account?.integrations?.whatsapp?.phone_number ? (
        <div className="d-flex flex-column ">
          <div className="d-flex flex-row align-items-center mb-1">
            <FaCheck style={{ color: "var(--reeler)" }} className="mr-2" />
            <span className="mr-3">Connected</span>
            <span>
              (
              {account.integrations.whatsapp.phone_number?.last_onboarded_time.substr(
                0,
                10
              )}
              )
            </span>
          </div>
          <div className="d-flex flex-row align-items-center mb-1">
            <span className="mr-3">Phone Number:</span>
            <span>
              {account.integrations.whatsapp.phone_number?.display_phone_number}
            </span>
          </div>
          <div className="d-flex flex-row align-items-center mb-1">
            <span className="mr-3">Verified name:</span>
            <span>
              {account.integrations.whatsapp.phone_number?.verified_name}
            </span>
          </div>

          <ReelerButton
            loading={loading}
            dispatch={() => fbLogin()}
            text="Login with Facebook"
            styleClass="btn-facebook"
          />
        </div>
      ) : (
        <ReelerButton
          loading={loading}
          dispatch={() => fbLogin()}
          text="Login with Facebook"
          styleClass="btn-facebook"
        />
      )}
    </div>
  ) : (
    <SpinnerComponent />
  )
}

export default WhatsappIntegration
